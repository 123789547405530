import { Component, ElementRef, inject, model, viewChild } from '@angular/core';
import config from '@config';
import { ScriptsService } from '@services/scripts.service';

@Component({
  selector: 'app-captcha',
  standalone: true,
  template: `<div #container data-cy="captcha"></div>`,
})
export class CaptchaComponent {
  private scriptsService = inject(ScriptsService);
  isLoading = model(true);
  isValid = model(false);
  container = viewChild<ElementRef>('container');

  constructor() {
    this.loadCaptcha();
  }

  private loadCaptcha() {
    this.scriptsService.load('aws-captcha', config.captchaUrl).subscribe(() => {
      this.initCaptcha();
    });
  }

  private initCaptcha() {
    AwsWafCaptcha.renderCaptcha(this.container()?.nativeElement, {
      apiKey: config.captchaApiKey,
      disableLanguageSelector: true,
      skipTitle: true,
      onSuccess: () => {
        this.isValid.set(true);
      },
      onError: () => {
        this.isValid.set(!config.captchaEnabled);
      },
    });
    this.isLoading.set(false);
  }
}
